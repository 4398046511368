import React, { useState, useEffect, useCallback, KeyboardEvent } from "react";
import Location from "./Location";

import nominatimServices from "services/apple/location";
import { LocationModelType, SearchLocationProps } from "./types";
import { SearchLocationContainer } from "./Location.styled";
import useDebounce from "hooks/debounce/useDebounce";
import { useQuery } from "@tanstack/react-query";
import { createTokenMapkitLocation } from "services/webapp/mapkit/endpoints";
import { getLastProjects } from "services/webapp/projects/endpoints";
import { useNavigate } from "react-router-dom";
import useProjectStore from "context/project-store";
import { AccessTime, LocationOn } from "@mui/icons-material";
import classes from "./style.module.css";
const SearchLocation: React.FunctionComponent<SearchLocationProps> = ({
  onSelectLocation,
}) => {
  const [selectedLocation, setSelectedLocation] = useState(0);
  const { isShowlocations, setShowLocation, setLocation, location } =
    useProjectStore();
  const [searchText, setSearchText] = useState<string>("");
  const [locations, setLocations] = useState<Array<LocationModelType>>();
  const debouncedValue = useDebounce<string>(searchText, 250);
  const { data } = useQuery({
    queryKey: ["locationtoken"],
    queryFn: () => createTokenMapkitLocation({}),
  });
  const { data: projects } = useQuery({
    queryKey: ["last_projects"],
    queryFn: () => getLastProjects(null),
  });

  const navigate = useNavigate();
  const getLocations = useCallback(async () => {
    const res = await nominatimServices.searchLocation({
      params: { search: debouncedValue, token: data?.data.data || "" },
    });
    const locationsList = await res.json();
    setLocations(locationsList.results);
  }, [data?.data.data, debouncedValue]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setShowLocation(true);
    setSearchText(e.currentTarget.value);
    setLocation(null);
  };
  const projectsList = projects?.data?.data?.results;

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (locations?.length) {
        onSelectLocation(locations[selectedLocation]);
        setLocations([]);
        setShowLocation(false);
      }
    }
  };
  const handleKeyDownUp = (event: KeyboardEvent<HTMLDivElement>) => {
    if (locations?.length) {
      if (event.key === "ArrowUp" && selectedLocation > 0) {
        setSelectedLocation(selectedLocation - 1);
      } else if (
        event.key === "ArrowDown" &&
        selectedLocation < locations?.length - 1
      ) {
        setSelectedLocation(selectedLocation + 1);
      }
    }
  };

  return (
    <SearchLocationContainer
      onKeyDown={handleKeyDownUp}
      onClick={() => {
        setShowLocation(true);
        navigate("/map");
      }}
    >
      <input
        value={location?.name || searchText}
        placeholder="Pin a new location below, or Enter a new address here or Coordinates using the + button"
        onKeyDown={handleKeyDown}
        className={classes.search_input}
        onChange={handleSearch}
      />
      {isShowlocations && (
        <div
          style={{
            backgroundColor: "#ffffff",
            position: "absolute",
            padding: locations?.length || projectsList?.length ? "4px" : "0px",
            top: "75px",
            width: "100%",
            borderRadius: "4px",
          }}
        >
          {locations &&
            locations?.length > 0 &&
            locations?.map((loc, index) => (
              <Location
                isActive={selectedLocation === index}
                key={Math.random()}
                locationData={loc}
                onClick={(data) => {
                  onSelectLocation(data);
                  setLocations([]);
                  setShowLocation(false);
                }}
                icon={<LocationOn color="primary" />}
              />
            ))}
          {projectsList?.map((p, index) => {
            return (
              <Location
                isActive={false}
                key={p.name + Math.random()}
                locationData={{
                  coordinate: {
                    latitude: Number(p.location.lat),
                    longitude: Number(p.location.lng),
                  },
                  name: p.name,
                  country: p.location.country,
                }}
                onClick={(data) => {
                  navigate(`/project?p=${p.id}`);
                  setLocations([]);
                  setShowLocation(false);
                }}
                icon={<AccessTime />}
              />
            );
          })}
        </div>
      )}
    </SearchLocationContainer>
  );
};

export default SearchLocation;
