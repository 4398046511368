import styled from "@emotion/styled";

export const SearchLocationContainer = styled("div")({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  gap: "20px",
  width: "100%",
  maxWidth: "600px",
  minWidth: "300px",
});

export const LocationContainer = styled("div")(
  (props: { isActive: boolean }) => ({
    backgroundColor: props.isActive ? "rgba(255, 165, 0,0.2);" : "white",
    display: "flex",
    alignItems: "center",
    padding: "10px 0px",
    width: "100%",
    cursor: "pointer",
    gap: "16px",
    fontSize: "12px",
  })
);
