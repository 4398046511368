import CircularProgress from "@mui/material/CircularProgress";

const Loader = ({ size = 50, width = "100%", marginTop = "20px" }) => {
  return (
    <div
      style={{
        width: width,
        display: "flex",
        justifyContent: "center",
        marginTop: marginTop,
      }}
    >
      <CircularProgress size={size} />
    </div>
  );
};

export default Loader;
